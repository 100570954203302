import tools from '@/js/tools.js'
 
function generateCodeVerifier() {
    var code_verifier = generateRandomString(43)
    return code_verifier;
};

function generateRandomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

function getToken() {
    var result = generateRandomString(32);
    return btoa(result);
};

function generateCodeChallenge(code_verifier) {
    let code_challenge = base64URL(CryptoJS.SHA256(code_verifier))
    return code_challenge;
}
function base64URL(string) {
    return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
};
export default {
   
    redirectToZaloLoginPage() {
        
        localStorage.removeItem("codeVerifier");


        // for Node.js https://www.npmjs.com/package/pkce-challenge
        var codeVerifier = generateCodeVerifier();
        
        var codeChallenge = generateCodeChallenge(codeVerifier);

        var state = getToken();
        var encodedState = encodeURIComponent(state);

        let callbackUrl = window.location.origin + "/zaloCallBack";
        
        var encodedCallbackUrl = encodeURIComponent(callbackUrl);

        localStorage.setItem("codeVerifier", codeVerifier);

        let url = "https://oauth.zaloapp.com/v4/permission?app_id=4393802505468387765"
                    + "&redirect_uri=" + encodedCallbackUrl
                    + "&code_challenge=" + codeChallenge
                    + "&state=" + encodedState;
 
        //window.location.href = url;
        let new_window = tools.popupwindow(url,'', 550, 570);

    },

}